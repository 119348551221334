const scriptEl = document.querySelector("script#vg-vgn-loader");
const camelToKebab = str =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase()
  );

if (scriptEl !== null) {
  console.log('VGN loader ID detected. Checking for "defer" attribute...');
  if (scriptEl.hasAttribute("defer")) {
    const headerEl = document.createElement("vg-vgn-header");
    Object.entries(scriptEl.dataset).forEach(([key, value]) => {
      headerEl.setAttribute(camelToKebab(key), value);
    });
    document.body.prepend(headerEl);
  } else {
    console.error(
      'VGN did not detect the "defer" attribute on "script#vg-vgn-loader".'
    );
  }
}
